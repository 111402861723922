import request from './'
import { baseURL } from './'

// 登录
function login(data, params) {
    return request.post({
        url: '/login',
        data,
        params
    })
}

// 登出
function loginout(data, params) {
    return request.post({
        url: '/loginout',
        data,
        params
    })
}

// 菜单管理
const Menu = {
    // 获取所有菜单
    getTreeList: (data, params) =>
        request.post({ url: '/Menu/GetTreeList', data, params }),
    // 编辑菜单
    update: (data, params) =>
        request.post({ url: '/Menu/Update', data, params }),
    // 删除菜单
    delete: (data, params) =>
        request.post({ url: '/Menu/Delete', data, params }),
    // 新增菜单
    add: (data, params) => request.post({ url: '/Menu/Add', data, params })
}

// 参数设置-驾校设置
const DriverShop = {
    // 新增驾校
    add: (data, params) =>
        request.post({ url: '/DriverShop/Add', data, params }),
    // 编辑驾校
    update: (data, params) =>
        request.post({ url: '/DriverShop/Update', data, params }),
    // 获取驾校列表
    getList: (data, params) =>
        request.post({ url: '/DriverShop/GetPageList', data, params }),
    // 获取下拉
    getTreeList: (data, params) =>
        request.post({ url: '/DriverShop/GetTreeList', data, params }),
    // 账号是否可用
    updateIsKeyCanUse: (data, params) =>
        request.post({ url: '/DriverShop/UpdateIsKeyCanUse', data, params })
}

// 参数设置-公共功能
const PublicDataDictionary = {
    // 新增字典
    add: (data, params) =>
        request.post({ url: '/PublicDataDictionary/Add', data, params }),
    // 编辑字典
    update: (data, params) =>
        request.post({ url: '/PublicDataDictionary/Update', data, params }),
    // 删除字典
    delete: (data, params) =>
        request.post({ url: '/PublicDataDictionary/Delete', data, params }),
    // 获取列表
    getList: (data, params) =>
        request.post({
            url: '/PublicDataDictionary/GetPageList',
            data,
            params
        }),
    // 获取下拉
    getSelectItem: (data, params) =>
        request.post({
            url: '/PublicDataDictionary/GetSelectItem',
            data,
            params
        }),
    // 获取下级下拉数据
    getChildrenSelectItem: (data, params) =>
        request.post({
            url: '/PublicDataDictionary/GetChildrenSelectItem',
            data,
            params
        }),
    // 根据县区编码获得全部省市区名称
    getByCountryCode: (data, params) =>
        request.post({
            url: '/PublicDataDictionary/GetByCountryCode',
            data,
            params
        }),
    // 查找违章代码是否存在
    findPeccancyByKey: (data, params) =>
        request.post({
            url: '/PublicDataDictionary/FindPeccancyByKey',
            data,
            params
        })
}
// 专项分类
const SpecialType = {
    // 新增专项分类
    getPageList: (data, params) =>
        request.post({ url: '/SpecialType/GetPageList', data, params }),
    // 编辑专项分类
    getTreeList: (data, params) =>
        request.post({ url: '/SpecialType/GetTreeList', data, params }),
    // 删除专项分类
    add: (data, params) =>
        request.post({ url: '/SpecialType/Add', data, params }),
    // 编辑专项分类
    update: (data, params) =>
        request.post({ url: '/SpecialType/Update', data, params }),
    // 删除专项分类
    delete: (data, params) =>
        request.post({ url: '/SpecialType/Delete', data, params })
}
// 技巧分类
const SkillType = {
    // 新增技巧分类
    getPageList: (data, params) =>
        request.post({ url: '/SkillType/GetPageList', data, params }),
    // 编辑专项分类
    getTreeList: (data, params) =>
        request.post({ url: '/SkillType/GetTreeList', data, params }),
    // 删除专项分类
    add: (data, params) =>
        request.post({ url: '/SkillType/Add', data, params }),
    // 编辑专项分类
    update: (data, params) =>
        request.post({ url: '/SkillType/Update', data, params }),
    // 删除专项分类
    delete: (data, params) =>
        request.post({ url: '/SkillType/Delete', data, params })
}
// 参数设置-公共功能(公司账号)
const CompanyDataDictionary = {
    //合并接口
    GetSelectItemByCodeList: (data, params) =>
        request.post({
            url: '/CompanyDataDictionary/GetSelectItemByCodeList',
            data,
            params
        }),
    // 新增字典
    add: (data, params) =>
        request.post({ url: '/CompanyDataDictionary/Add', data, params }),
    // 编辑字典
    update: (data, params) =>
        request.post({ url: '/CompanyDataDictionary/Update', data, params }),
    // 删除字典
    delete: (data, params) =>
        request.post({ url: '/CompanyDataDictionary/Delete', data, params }),
    // 获取列表
    getList: (data, params) =>
        request.post({
            url: '/CompanyDataDictionary/GetPageList',
            data,
            params
        }),
    // 获取下拉
    getSelectItem: (data, params) =>
        request.post({
            url: '/CompanyDataDictionary/GetSelectItem',
            data,
            params
        }),
    // 获取下级下拉数据
    getChildrenSelectItem: (data, params) =>
        request.post({
            url: '/CompanyDataDictionary/GetChildrenSelectItem',
            data,
            params
        })
}

// 参数设置-校区管理
const TrainArea = {
    add: (data, params) =>
        request.post({ url: '/TrainArea/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/TrainArea/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/TrainArea/Delete', data, params }),
    getTreeList: (data, params) =>
        request.post({ url: '/TrainArea/GetTreeList', data, params }),
    getList: (data, params) =>
        request.post({ url: '/TrainArea/GetPageList', data, params }),
    // 禁止训练
    disTrain: (data, params) =>
        request.post({ url: '/api/ad/TrainArea/DisTrain', data, params }),
    // 修改车型
    updateCertificate: (data, params) =>
        request.post({ url: 'TrainArea/UpdateCertificate', data, params }),
    // 修改班型
    updateEnterClass: (data, params) =>
        request.post({ url: 'TrainArea/UpdateEnterClass', data, params }),
    // 获取下拉
    getSelectItem: (data, params) =>
        request.post({ url: '/TrainArea/GetSelectItem', data, params }),
    // 恢复训练
    enTrain: (data, params) =>
        request.post({ url: '/api/ad/TrainArea/EnTrain', data, params }),
    // 获取校区班别和车型
    GetEnterClassAndCerList: (data, params) =>
        request.post({
            url: '/TrainArea/GetEnterClassAndCerList',
            data,
            params
        }),
    // 修改是否展示
    updateIsShow: (data, params) =>
        request.post({ url: 'TrainArea/UpdateIsShow', data, params })
}

// 权限管理-部门管理
const Department = {
    add: (data, params) =>
        request.post({ url: '/Department/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Department/Update', data, params }),
    // 按照id寻找部门详情
    findDepartment: (data, params) =>
        request.post({ url: '/Department/FindDepartment', data, params }),
    getTreeList: (data, params) =>
        request.post({ url: '/Department/GetTreeList', data, params }),
    // 获取全部部门
    getList: (data, params) =>
        request.post({ url: '/Department/GetList', data, params }),
    // 获取全部部门下的招生人
    getDepartmentUserTreeList: (data, params) =>
        request.post({
            url: '/Department/GetDepartmentUserTreeList',
            data,
            params
        })
}
// 权限管理-用户管理
const User = {
    add: (data, params) => request.post({ url: '/User/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/User/Update', data, params }),
    quit: (data, params) => request.post({ url: '/User/Quit', data, params }),
    disable: (data, params) =>
        request.post({ url: '/User/Disable', data, params }),
    changePassword: (data, params) =>
        request.post({ url: '/User/ChangePassword', data, params }),
    setPassword: (data, params) =>
        request.post({ url: '/User/SetPassword', data, params }),
    enable: (data, params) =>
        request.post({ url: '/User/Enable', data, params }),
    getTreeList: (data, params) =>
        request.post({ url: '/User/GetTreeList', data, params }),
    getList: (data, params) =>
        request.post({ url: '/User/GetPageList', data, params }),
    updateDatePower: (data, params) =>
        request.post({ url: '/User/UpdateDatePower', data, params }),
    // 修改在职状态
    updateWorkState: (data, params) =>
        request.post({ url: '/User/UpdateWorkState', data, params }),
    // 修改角色、岗位
    updateRole: (data, params) =>
        request.post({ url: '/User/UpdateRole', data, params }),
    // 修改岗位类型
    updateWorkCode: (data, params) =>
        request.post({ url: '/User/UpdateWorkCode', data, params }),
    // 修改岗位等级
    updateLevelId: (data, params) =>
        request.post({ url: '/User/UpdateLevelId', data, params }),
    // 修改部门
    updateDepart: (data, params) =>
        request.post({ url: '/User/UpdateDepart', data, params }),
    // 修改校区
    updateTrainArea: (data, params) =>
        request.post({ url: '/User/UpdateTrainArea', data, params }),
    // 修改带教班型
    updateEnterClass: (data, params) =>
        request.post({ url: '/User/UpdateEnterClass', data, params }),
    // 修改带教车型
    updateCertificate: (data, params) =>
        request.post({ url: '/User/UpdateCertificate', data, params }),
    // 挂职属性
    getWorkCodeList: (data, params) =>
        request.post({ url: '/User/GetWorkCodeList', data, params }),
    // 学历
    getEducationCodeList: (data, params) =>
        request.post({ url: '/User/GetEducationCodeList', data, params }),
    // 教练证鉴定级别
    getCertificateCheckCodeList: (data, params) =>
        request.post({
            url: '/User/GetCertificateCheckCodeList',
            data,
            params
        }),
    // 教练证状态级别
    getCoachCertificateCodeList: (data, params) =>
        request.post({
            url: '/User/GetCoachCertificateCodeList',
            data,
            params
        }),
    // 导出excel
    exportPageListUrl: `${baseURL}/User/ExportPageList`,
    // 获取用户详情
    findDetail: (data, params) =>
        request.post({ url: '/User/FindDetail', data, params }),
    // 获取用户日志
    getLogPageList: (data, params) =>
        request.post({ url: '/User/GetLogPageList', data, params }),
    // 根据用户名称查询部门
    findUserDepart: (data, params) =>
        request.post({ url: '/User/FindUserDepart', data, params }),
    // 更新任职科目
    updateThemeCodeList: (data, params) =>
        request.post({ url: '/User/UpdateTheme', data, params }),
    UnBindOpenId: (data, params) =>
        request.post({ url: '/User/UnBindOpenId', data, params }),
    SendAllocateInfo: (data, params) =>
        request.post({ url: '/User/SendAllocateInfo', data, params }),
    GetCompanyList: account =>
        request.get({ url: '/User/GetCompanyList?account=' + account }),
    SwitchCompany: (data, params) =>
        request.post({ url: '/User/SwitchCompany', data, params }),
    ValidateAccount: (data, params) =>
        request.post({ url: '/User/ValidateAccount', data, params }),
    GetFilterColumn: (data, params) =>
        request.post({ url: '/User/GetFilterColumn', data, params }),
    SaveFilterColumn: (data, params) =>
        request.post({ url: '/User/SaveFilterColumn', data, params })
}
// 权限管理-角色管理
const wokerment = {
    add: (data, params) => request.post({ url: '/Role/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Role/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Role/Delete', data, params }),
    getList: (data, params) =>
        request.post({ url: '/Role/GetPageList', data, params }),
    getTreeList: (data, params) =>
        request.post({ url: '/Role/GetTreeList', data, params }),
    // 查找当前角色的权限配置
    findRoleAccessConfiguration: (data, params) =>
        request.post({
            url: '/Role/FindRoleAccessConfiguration',
            data,
            params
        }),
    getRelationUserPageList: (data, params) =>
        request.post({ url: '/Role/GetRelationUserPageList', data, params })
}
// 参数管理-报考班型
const EnterClass = {
    add: (data, params) =>
        request.post({ url: '/EnterClass/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/EnterClass/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/EnterClass/Delete', data, params }),
    getList: (data, params) =>
        request.post({ url: '/EnterClass/GetPageList', data, params }),
    getTreeList: (data, params) =>
        request.post({ url: '/EnterClass/GetTreeList', data, params })
}
// 参数管理-支出类目
const ExpendThingCategory = {
    add: (data, params) =>
        request.post({ url: '/ExpendThingCategory/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/ExpendThingCategory/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/ExpendThingCategory/Delete', data, params }),
    getList: (data, params) =>
        request.post({ url: '/ExpendThingCategory/GetPageList', data, params }),
    getTreeList: (data, params) =>
        request.post({ url: '/ExpendThingCategory/GetTreeList', data, params })
}
// 展示教练
const Coach = {
    add: (data, params) => request.post({ url: '/Coach/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Coach/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Coach/Delete', data, params }),
    getPageList: (data, params) =>
        request.post({ url: '/Coach/GetPageList', data, params })
}
// 驾校管理-车辆管理
const Car = {
    // 新增车辆
    add: (data, params) => request.post({ url: '/Car/Add', data, params }),
    // 编辑车辆
    update: (data, params) =>
        request.post({ url: '/Car/Update', data, params }),
    // 删除车辆
    delete: (data, params) =>
        request.post({ url: '/Car/Delete', data, params }),
    // 修改备注
    updateRemark: (data, params) =>
        request.post({ url: '/Car/UpdateRemark', data, params }),
    // 修改车型备注
    updateModelsRemark: (data, params) =>
        request.post({ url: '/Car/UpdateModelsRemark', data, params }),
    // 修改在用校区
    updateTrainArea: (data, params) =>
        request.post({ url: '/Car/UpdateTrainArea', data, params }),
    // 修改负责人
    updateUser: (data, params) =>
        request.post({ url: '/Car/UpdateUser', data, params }),
    // 修改所有人
    updateBelonger: (data, params) =>
        request.post({ url: '/Car/UpdateBelonger', data, params }),
    // 修改关联人
    updateRelationUser: (data, params) =>
        request.post({ url: '/Car/UpdateRelationUser', data, params }),
    // 禁用
    disable: (data, params) =>
        request.post({ url: '/Car/Disable', data, params }),
    // 启用
    enable: (data, params) =>
        request.post({ url: '/Car/Enable', data, params }),
    // 获取车辆分页列表
    getPageList: (data, params) =>
        request.post({ url: '/Car/GetPageList', data, params }),
    // 获取车辆分页列表
    getList: (data, params) =>
        request.post({ url: '/Car/GetPageList', data, params }),
    // 修改年检时间
    updateMotTime: (data, params) =>
        request.post({ url: '/Car/UpdateMOTTime', data, params }),
    // 修改运营险时间
    updateBusinessTime: (data, params) =>
        request.post({ url: '/Car/UpdateBusinessTime', data, params }),
    // 修改交强险时间
    updateMustTime: (data, params) =>
        request.post({ url: '/Car/UpdateMustTime', data, params }),
    // 修改其他保险时间
    updateOtherTime: (data, params) =>
        request.post({ url: '/Car/UpdateOtherTime', data, params }),
    // 获取车辆事故分页
    getAccidentPageList: (data, params) =>
        request.post({ url: '/Car/GetAccidentPageList', data, params }),
    // 获得事故类型枚举集合
    getAccidentCodeList: (data, params) =>
        request.post({ url: '/Car/GetAccidentCodeList', data, params }),
    // 新增事故
    addAccident: (data, params) =>
        request.post({ url: '/Car/AddAccident', data, params }),
    // 编辑事故
    updateAccident: (data, params) =>
        request.post({ url: '/Car/UpdateAccident', data, params }),
    // 删除事故
    deleteAccident: (data, params) =>
        request.post({ url: '/Car/DeleteAccident', data, params }),
    // 获取违章事故分页
    getPeccancyPageList: (data, params) =>
        request.post({ url: '/Car/GetPeccancyPageList', data, params }),
    // 获得违章类型枚举集合
    getPeccancyCodeList: (data, params) =>
        request.post({ url: '/Car/GetPeccancyCodeList', data, params }),
    // 获得车辆状态枚举集合
    getSatusCodeList: (data, params) =>
        request.post({ url: '/Car/GetSatusCodeList', data, params }),
    // 新增违章
    addPeccancy: (data, params) =>
        request.post({ url: '/Car/AddPeccancy', data, params }),
    // 新增违章
    updatePenccancy: (data, params) =>
        request.post({ url: '/Car/UpdatePeccancy', data, params }),
    // 删除违章
    deletePeccancy: (data, params) =>
        request.post({ url: '/Car/DeletePeccancy', data, params }),
    // 处理违章
    updatePeccancyCode: (data, params) =>
        request.post({ url: '/Car/UpdatePeccancyCode', data, params }),
    // 获取车辆日志
    getLogPageList: (data, params) =>
        request.post({ url: '/Car/GetLogPageList', data, params }),
    // 导出车辆分页
    exportPageList: params =>
        request.get({ url: '/Car/ExportPageList', params }),
    //获取车辆下拉
    GetTreeList: (data, params) =>
        request.post({ url: '/Car/GetTreeList', data, params }),
    //获取保养记录
    GetMaintenancePageList: (data, params) =>
        request.post({ url: '/Car/GetMaintenancePageList', data, params }),
    //新增保养记录
    AddMaintenance: (data, params) =>
        request.post({ url: '/Car/AddMaintenance', data, params }),
    //批量新增保养
    BatchAddMaintenance: (data, params) =>
        request.post({ url: '/Car/BatchAddMaintenance', data, params }),
    //导出保养记录
    exportMain: `${baseURL}/Car/ExportMaintenancePageList`,
    exportPageListUrl: `${baseURL}/Car/ExportPageList`,
    // 删除记录
    deleteCarLog: (data, params) =>
        request.post({ url: '/Car/DeleteCarLog', data, params }),
    deleteLog: (data, params) =>
        request.post({ url: '/Car/DeleteLog', data, params }),
    // 获取油耗状态页面配置
    getCarConfig: (data, params) =>
        request.post({ url: '/Car/GetCarConfig', data, params }),
    // 修改油耗状态页面配置
    UpdateCarConfig: (data, params) =>
        request.post({ url: '/Car/UpdateCarConfig', data, params }),
    // 获取车辆责任人
    GetCarUserSelectItem: (data, params) =>
        request.post({ url: '/Car/GetCarUserSelectItem', data, params }),
    // 修改使用状态
    UpdateStatusCode: (data, params) =>
        request.post({ url: '/Car/UpdateStatusCode', data, params }),
    updateDepart: (data, params) =>
        request.post({ url: '/Car/UpdateDepart', data, params }),
    getRecentLogImage: (data, params) =>
        request.post({ url: 'car/GetRecentLogImage', data, params })
}
// 权限管理-操作权限
const DataPower = {
    add: (data, params) =>
        request.post({ url: '/DataPower/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/DataPower/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/DataPower/Delete', data, params }),
    getList: (data, params) =>
        request.post({ url: '/DataPower/GetPageList', data, params }),
    getTreeList: (data, params) =>
        request.post({ url: '/DataPower/GetTreeList', data, params }),
    getRelationUserPageList: (data, params) =>
        request.post({
            url: '/DataPower/GetRelationUserPageList',
            data,
            params
        })
}

// 权限管理-公司管理

const Company = {
    add: (data, params) => request.post({ url: '/Company/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Company/Update', data, params }),
    disable: (data, params) =>
        request.post({ url: '/Company/Disabled', data, params }),
    enable: (data, params) =>
        request.post({ url: '/Company/Enabled', data, params }),
    getList: (data, params) =>
        request.post({ url: '/Company/GetPageList', data, params }),
    // 获取驾校管理分页列表
    getCompanyDriverShopPageList: (data, params) =>
        request.post({ url: '/CompanyDriverShop/GetPageList', data, params }),
    // 编辑
    updateCompanyDriverShop: (data, params) =>
        request.post({ url: '/CompanyDriverShop/Update', data, params }),
    // 获取key不可用数量
    getKeyCanNotUseCount: (data, params) =>
        request.post({
            url: '/CompanyDriverShop/GetKeyCanNotUseCount',
            data,
            params
        }),
    // 获取身份属性的id，教练与行政的
    getSpecialIdentity: (data, params) =>
        request.post({ url: '/Company/GetSpecialIdentity', data, params }),
    // 编辑教练、行政的id
    updateSpecialIdentity: (data, params) =>
        request.post({ url: '/Company/UpdateSpecialIdentity', data, params }),
    // 获取公司日志分页列表
    getLogPageList: (data, params) =>
        request.post({ url: '/Company/GetLogPageList', data, params })
}

// 上传控制器
const Upload = {
    upload: 'https://api.xianghuanjia.com/api/ad/Upload/Upload'
    // upload: 'http://testapi1234.wanxiaocar.com/api/ad/Upload/Upload'
}

// 线索
const Clue = {
    add: (data, params) => request.post({ url: '/Clue/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Clue/Update', data, params }),
    getList: (data, params) =>
        request.post({ url: '/Clue/GetPageList', data, params }),
    addLog: (data, params) =>
        request.post({ url: '/Clue/AddLog', data, params }),
    updateLog: (data, params) =>
        request.post({ url: '/Clue/UpdateLog', data, params }),
    getLogPageList: (data, params) =>
        request.post({ url: '/Clue/GetLogPageList', data, params }),
    getOppositeUserLogPageList: (data, params) =>
        request.post({ url: '/Clue/GetOppositeUserLogPageList', data, params }),
    updateOppositeUser: (data, params) =>
        request.post({ url: '/Clue/UpdateOppositeUser', data, params })
}
// 参数设置-缴费状态
const PayState = {
    add: (data, params) => request.post({ url: '/PayState/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/PayState/Update', data, params }),
    getList: (data, params) =>
        request.post({ url: '/PayState/GetPageList', data, params }),
    getTreeList: (data, params) =>
        request.post({ url: '/PayState/GetTreeList', data, params }),
    // 获取缴费状态接口
    getPay: (data, params) =>
        request.post({ url: '/PayState/GetPayAbnormalConfig', data, params }),
    // 修改缴费状态
    updatePay: (data, params) =>
        request.post({ url: '/PayState/UpdatePayAbnormalConfig', data, params })
}
// 报名管理-招生管理
const Student = {
    add: (data, params) => request.post({ url: '/Student/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Student/Update', data, params }), //修改学员
    updateRemark: (data, params) =>
        request.post({ url: '/Student/UpdateRemark', data, params }),
    updateTrainArea: (data, params) =>
        request.post({ url: '/Student/UpdateTrainArea', data, params }),
    updateThemeTwoCoach: (data, params) =>
        request.post({ url: '/Student/UpdateThemeTwoCoach', data, params }),
    //修改科二教练
    updateThemeThreeCoach: (data, params) =>
        request.post({ url: '/Student/UpdateThemeThreeCoach', data, params }),
    // 删除科二教练
    revertToLastThemeTwoCoach: (data, params) =>
        request.post({
            url: '/Student/RevertToLastThemeTwoCoach',
            data,
            params
        }),
    // 删除科三教练
    revertToLastThemeThreeCoach: (data, params) =>
        request.post({
            url: '/Student/RevertToLastThemeThreeCoach',
            data,
            params
        }),
    updateMakeCard: (data, params) =>
        request.post({ url: '/Student/UpdateMakeCard', data, params }),
    updateMaterialCode: (data, params) =>
        request.post({ url: '/Student/updateMaterialCode', data, params }),
    updateMaterialType: (data, params) =>
        request.post({ url: '/Student/UpdateMaterialType', data, params }),
    updateEnterClass: (data, params) =>
        request.post({ url: '/Student/UpdateEnterClass', data, params }),
    updateReference: (data, params) =>
        request.post({ url: '/Student/UpdateReference', data, params }),
    updateCreater: (data, params) =>
        request.post({ url: '/Student/UpdateCreater', data, params }),
    updateTimeCode: (data, params) =>
        request.post({ url: '/Student/UpdateTimeCode', data, params }),
    updateCertificate: (data, params) =>
        request.post({ url: '/Student/UpdateCertificate', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Student/Delete', data, params }),
    getList: (data, params) =>
        request.post({ url: '/Student/GetPageList', data, params }),
    getLogPageList: (data, params) =>
        request.post({ url: '/Student/GetLogPageList', data, params }),
    //申请退学
    wantDrop: (data, params) =>
        request.post({ url: '/Student/WantDrop', data, params }),
    //身份证是否存在
    findCard: (data, params) =>
        request.post({ url: '/Student/FindByIdCard', data, params }),

    // updateThemeTwoCoach: (data, params) => request.post({ url: '/Student/UpdateThemeTwoCoach', data, params }),
    //修改校区
    UpdateTrainArea: (data, params) =>
        request.post({ url: '/Student/UpdateTrainArea', data, params }),
    //修改科三教练
    UpdateThemeThreeCoach: (data, params) =>
        request.post({ url: '/Student/UpdateThemeThreeCoach', data, params }),
    //修改学员标签
    UpdateTag: (data, params) =>
        request.post({ url: '/Student/UpdateTag', data, params }),
    //修改发放状态
    UpdateReferenceMoneyCode: (data, params) =>
        request.post({
            url: '/Student/UpdateReferenceMoneyCode',
            data,
            params
        }),
    //修改佣金金额
    UpdateReferenceMoney: (data, params) =>
        request.post({ url: '/Student/UpdateReferenceMoney', data, params }),
    //批量修改佣金金额
    BatchUpdateReferenceMoney: (data, params) =>
        request.post({
            url: '/Student/BatchUpdateReferenceMoney',
            data,
            params
        }),
    //获取已毕业头部
    getGradutePageHead: (data, params) =>
        request.post({ url: '/Student/GetGradutePageHead', data, params }),
    // 修改报班备注
    updateEnterClassRemark: (data, params) =>
        request.post({ url: '/Student/UpdateEnterClassRemark', data, params }),
    // 添加学员标注
    addFollowStudent: (data, params) =>
        request.post({ url: '/Student/AddFollowStudent', data, params }),
    // 编辑学员标注
    updateFollowStudent: (data, params) =>
        request.post({ url: '/Student/UpdateFollowStudent', data, params }),
    // 删除学员标注
    deleteFollowStudent: (data, params) =>
        request.post({ url: '/Student/DeleteFollowStudent', data, params }),
    exportPageListUrl: `${baseURL}/Student/ExportPageList`,
    // 转出委培
    outWeipei: (data, params) =>
        request.post({ url: '/Student/OutWeipei', data, params }),
    // 更细委培状态
    updateIsWeipei: (data, params) =>
        request.post({ url: '/Student/updateIsWeipei', data, params }),
    // 更新委培备注
    updateWeipeiRemark: (data, params) =>
        request.post({ url: '/Student/UpdateWeipeiRemark', data, params }),
    // 修改补考保险
    updateStudentExamInsure: (data, params) =>
        request.post({ url: '/Student/UpdateStudentExamInsure', data, params }),
    // 设置超龄
    UpdateAgeMax: (data, params) =>
        request.post({ url: '/Student/UpdateAgeMax', data, params }),
    // 设置代发放状态
    UpdateReferenceMoneyRule: (data, params) =>
        request.post({
            url: '/Student/UpdateReferenceMoneyRule',
            data,
            params
        }),
    // 学费统计
    GetMoneyStat: (data, params) =>
        request.post({ url: '/Student/GetMoneyStat', data, params }),
    // 在库统计
    getInStat: (data, params) =>
        request.post({ url: '/Student/GetInStat', data, params }),
    // 修改过期时间
    updateTimeOutDate: (data, params) =>
        request.post({ url: '/Student/UpdateTimeOutDate', data, params }),
    // 月报名状况
    getMonthStat: (data, params) =>
        request.post({ url: '/Student/GetMonthStat', data, params }),
    // 时间范围内新增报名统计
    getDayStat: (data, params) =>
        request.post({ url: '/Student/GetDayStat', data, params }),
    // 获取时间内月份统计
    getPerMonthStat: (data, params) =>
        request.post({ url: '/Student/GetPerMonthStat', data, params }),
    // 年度报名状况
    getSeasonStat: (data, params) =>
        request.post({ url: '/Student/GetSeasonStat', data, params }),
    // 获取超龄配置
    GetRuleConfig: (data, params) =>
        request.post({ url: '/Student/GetRuleConfig', data, params }),
    // 批量修改委培
    BatchUpdateIsWeipei: (data, params) =>
        request.post({ url: '/Student/BatchUpdateIsWeipei', data, params }),
    // 批量添加标记学员
    BatchAddFollowStudent: (data, params) =>
        request.post({ url: '/Student/BatchAddFollowStudent', data, params }),
    // 批量修改报班
    UpdateEnterClassId: (data, params) =>
        request.post({ url: '/Student/UpdateEnterClassId', data, params }),
    // 修改二级推荐人
    UpdateSecondReference: (data, params) =>
        request.post({ url: '/Student/UpdateSecondReference', data, params }),
    // 修改备注
    UpdateRemark: (data, params) =>
        request.post({ url: '/Student/UpdateRemark', data, params }),
    // 修改报名备注
    UpdateFromRemark: (data, params) =>
        request.post({ url: '/Student/UpdateFromRemark', data, params }),
    // 修改发放备注
    UpdateReferenceRemark: (data, params) =>
        request.post({ url: '/Student/UpdateReferenceRemark', data, params }),
    // 修改标注备注
    updateMarker: (data, params) =>
        request.post({ url: '/Student/UpdateMarker', data, params }),
    // 修改是否增驾
    updateIsUpCertificate: (data, params) =>
        request.post({ url: '/Student/UpdateIsUpCertificate', data, params }),
    // 恢复数据
    restore: (data, params) =>
        request.post({ url: '/Student/Restore', data, params }),
    // 修改报班财务备注
    updateEnterClassFinancialRemark: (data, params) =>
        request.post({
            url: '/Student/UpdateEnterClassFinancialRemark',
            data,
            params
        }),
    // 批量修改报班财务备注
    batchUpdateEnterClassFinancialRemark: (data, params) =>
        request.post({
            url: '/Student/BatchUpdateEnterClassFinancialRemark',
            data,
            params
        }),
    // 编辑客服专员
    updateFeedBackUser: (data, params) =>
        request.post({ url: '/Student/UpdateFeedBackUser', data, params }),
    // 编辑正考费用
    updateExamPay: (data, params) =>
        request.post({ url: '/Student/UpdateExamPay', data, params }),
    //删除考试缴费
    deleteExamPay: (data, params) =>
        request.post({ url: '/Student/DeleteExamPay', data, params }),
    // 修改实缴总价
    UpdateCollectMoney: (data, params) =>
        request.post({ url: '/Student/UpdateCollectMoney', data, params }),
    SaveAllocationStudent: (data, params) =>
        request.post({ url: '/Student/SaveAllocationStudent', data, params }),
    GetNoAllocate: (data, params) =>
        request.post({ url: '/Student/GetNoAllocate', data, params }),
    AllocateThemeTwoCoach: (data, params) =>
        request.post({ url: '/Student/AllocateThemeTwoCoach', data, params }),
    AllocateThemeThreeCoach: (data, params) =>
        request.post({ url: '/Student/AllocateThemeThreeCoach', data, params }),
    ConfirmCoach: data => request.post({ url: '/Student/ConfirmCoach', data }),
    CoachReject: data => request.post({ url: '/Student/CoachReject', data }),
    GetStudentDropInfo: data =>
        request.post({ url: '/Student/GetStudentDropInfo', data }),
    GetRiskLogPageList: data =>
        request.post({ url: '/Student/GetRiskLogPageList', data }),
    GetRiskLogCodeList: data =>
        request.post({ url: '/Student/GetRiskLogCodeList', data }),
    GetRiskLogCreatorList: data =>
        request.post({ url: '/Student/GetRiskLogCreatorList', data }),
    updateIdCard: (data, params) =>
        request.post({ url: '/Student/UpdateIdCard', data, params }),
    updateName: (data, params) =>
        request.post({ url: '/Student/UpdateName', data, params }),
    updateEnteredDate: (data, params) =>
        request.post({ url: '/Student/UpdateEnteredDate', data, params }),
    // student/AllocationReject
    AllocationReject: (data, params) =>
        request.post({ url: '/Student/AllocationReject', data, params }),
    RevertToLastReferenceMoneyCode: (data, params) =>
        request.post({
            url: '/Student/RevertToLastReferenceMoneyCode',
            data,
            params
        }),
    GetDayStatNew: (data, params) =>
        request.post({ url: '/Student/GetDayStatNew', data, params }),
    GetHomeData: (data, params) =>
        request.post({ url: '/Student/GetHomeData', data, params }),
    updateMobile: (data, params) =>
        request.post({ url: '/Student/UpdateMobile', data, params }),
    UpdateAccept: (data, params) =>
        request.post({ url: '/Student/UpdateAccept', data, params })
}

// 报名管理-退学管理
const PayOrder = {
    // 新增缴费
    add: (data, params) => request.post({ url: '/PayOrder/Add', data, params }),
    // 编辑缴费
    update: (data, params) =>
        request.post({ url: '/PayOrder/Update', data, params }),
    // 删除缴费
    delete: (data, params) =>
        request.post({ url: '/PayOrder/Delete', data, params }),
    // 获取缴费分页列表
    getList: (data, params) =>
        request.post({ url: '/PayOrder/GetPageList', data, params }),
    //获取退学分页列表
    getDropPageList: (data, params) =>
        request.post({ url: '/PayOrder/GetDropPageList', data, params }),
    // 取消退学
    deleteDrop: (data, params) =>
        request.post({ url: '/PayOrder/DeleteDrop', data, params }),
    // 编辑退学退款
    updateDrop: (data, params) =>
        request.post({ url: '/PayOrder/UpdateDrop', data, params }),
    // 确认退学退款
    confirmDrop: (data, params) =>
        request.post({ url: '/PayOrder/ConfirmDrop', data, params }),
    // 确认缴费
    confirm: (data, params) =>
        request.post({ url: '/PayOrder/Confirm', data, params }),
    // 收款统计
    GetStat: (data, params) =>
        request.post({ url: '/PayOrder/GetStat', data, params }),
    // 收款统计核对备注
    updateStatRemark: (data, params) =>
        request.post({ url: '/PayOrder/UpdateStatRemark', data, params }),
    UpdatePayRemark: (data, params) =>
        request.post({ url: '/PayOrder/UpdatePayRemark', data, params }),
    // 导出excel
    exportPageListUrl: `${baseURL}/PayOrder/ExportPageList`
}

// 收款账户设置
const PayAccount = {
    // 新增收款账户设置
    add: (data, params) =>
        request.post({ url: '/PayAccount/Add', data, params }),
    // 编辑收款账户设置
    update: (data, params) =>
        request.post({ url: '/PayAccount/Update', data, params }),
    // 删除收款账户设置
    delete: (data, params) =>
        request.post({ url: '/PayAccount/Delete', data, params }),
    // 获取收款账户设置
    getList: (data, params) =>
        request.post({ url: '/PayAccount/GetPageList', data, params }),
    //获取收款账户设置下拉
    getTreeList: (data, params) =>
        request.post({ url: '/PayAccount/GetTreeList', data, params })
}

const ExamPlace = {
    // 新增考场
    add: (data, params) =>
        request.post({ url: '/ExamPlace/Add', data, params }),
    // 编辑考场
    update: (data, params) =>
        request.post({ url: '/ExamPlace/Update', data, params }),
    // 删除考场
    delete: (data, params) =>
        request.post({ url: '/ExamPlace/Delete', data, params }),
    // 获取考场分页列表
    getList: (data, params) =>
        request.post({ url: '/ExamPlace/GetPageList', data, params }),
    //获取考场分页列表
    getTreeList: (data, params) =>
        request.post({ url: '/ExamPlace/GetTreeList', data, params })
}

// 参数设置-考试缴费金额
const ThemeExamPay = {
    // 新增试缴费金额
    add: (data, params) =>
        request.post({ url: '/ThemeExamPay/Add', data, params }),
    // 编辑试缴费金额
    update: (data, params) =>
        request.post({ url: '/ThemeExamPay/Update', data, params }),
    // 删除考试缴费金额
    delete: (data, params) =>
        request.post({ url: '/ThemeExamPay/Delete', data, params }),
    // 获取考试缴费金额列表
    getList: (data, params) =>
        request.post({ url: '/ThemeExamPay/GetPageList', data, params }),
    // 获取缴费金额数据
    getPayList: (data, params) =>
        request.post({ url: '/ThemeExamPay/GetList', data, params })
}

// 驾校管理-油卡管理

const OilCard = {
    // 新增油卡
    add: (data, params) => request.post({ url: '/OilCard/Add', data, params }),
    // 编辑油卡
    update: (data, params) =>
        request.post({ url: '/OilCard/Update', data, params }),
    // 获取油卡列表
    getList: (data, params) =>
        request.post({ url: '/OilCard/GetPageList', data, params }),
    // 获取油卡树形列表
    getTreeList: (data, params) =>
        request.post({ url: '/OilCard/GetTreeList', data, params }),
    // 获取油卡圈存分页
    getQuanCunPageList: (data, params) =>
        request.post({ url: '/OilCard/GetQuanCunPageList', data, params }),
    // 新增圈存
    addQuanCun: (data, params) =>
        request.post({ url: '/OilCard/AddQuanCun', data, params }),
    // 删除圈存
    deleteQuanCun: (data, params) =>
        request.post({ url: '/OilCard/DeleteQuanCun', data, params }),
    // 获取油卡日至
    getLogPageList: (data, params) =>
        request.post({ url: '/OilCard/GetLogPageList', data, params })
}
const Exam = {
    // 新增考试
    add: (data, params) => request.post({ url: '/Exam/Add', data, params }),
    //修改考试
    update: (data, params) =>
        request.post({ url: '/Exam/Update', data, params }),
    //修改删除
    delete: (data, params) =>
        request.post({ url: '/Exam/Delete', data, params }),
    //查询考试分页
    getList: (data, params) =>
        request.post({ url: '/Exam/GetPageList', data, params }),
    //根据id查询考试记录
    getPageListByStudentId: (data, params) =>
        request.post({ url: '/Exam/GetPageListByStudentId', data, params }),
    //根据学员获取考试次数
    getTimes: (data, params) =>
        request.post({ url: '/Exam/GetExamCount', data, params }),
    //添加考试缴费
    addExamMoney: (data, params) =>
        request.post({ url: '/Exam/UpdateExamPay', data, params }),
    //批量修改考试
    BatchUpdate: (data, params) =>
        request.post({ url: '/Exam/BatchUpdate', data, params }),
    //获取考试数据
    GetThemeTimeResult: (data, params) =>
        request.post({ url: '/Exam/GetThemeTimeResult', data, params }),
    exportPageListUrl: `${baseURL}/Exam/ExportPageList`,
    // 获取合格率统计
    getResultStat: (data, params) =>
        request.post({ url: '/Exam/GetResultStat', data, params }),
    // 获取考试预约统计
    getAppointmentStat: (data, params) =>
        request.post({ url: '/Exam/GetAppointmentStat', data, params }),
    // 获取合格率查询
    getPassStat: (data, params) =>
        request.post({ url: '/Exam/GetPassStat', data, params }),
    // 修改考试结果
    updateThemeTimeResult: (data, params) =>
        request.post({ url: '/Exam/UpdateThemeTimeResult', data, params }),
    // 获取考试数据获取异常记录
    getExamErrorLogPageList: (data, params) =>
        request.post({ url: '/Exam/GetExamErrorLogPageList', data, params }),
    //修改抓取异常是否已读/api/ad/Exam/SalaryMoneyBatchUpdate
    UpdateExamErrorIsRead: (data, params) =>
        request.post({ url: '/Exam/UpdateExamErrorIsRead', data, params }),
    // 批量培训费用
    salaryMoneyBatchUpdate: (data, params) =>
        request.post({ url: '/Exam/SalaryMoneyBatchUpdate', data, params }),
    // 批量工资备注
    salaryRemarkBatchUpdate: (data, params) =>
        request.post({ url: '/Exam/SalaryRemarkBatchUpdate', data, params }),
    // 获取工资
    getCoachSalary: (data, params) =>
        request.post({ url: '/Exam/GetCoachSalary', data, params }),
    // 导出
    GetImportResult: `${baseURL}/Exam/GetImportResult`,
    // 获取考试数据
    //批量新增科四
    batchAdd: (data, params) =>
        request.post({ url: '/Exam/BatchAdd', data, params })
}

// 驾校管理-油耗管理

const OilWaste = {
    // 新增油耗
    add: (data, params) => request.post({ url: '/OilWaste/Add', data, params }),
    // 编辑油耗
    update: (data, params) =>
        request.post({ url: '/OilWaste/Update', data, params }),
    // 删除油耗
    delete: (data, params) =>
        request.post({ url: '/OilWaste/Delete', data, params }),
    // 编辑异常状态
    updateErrorCode: (data, params) =>
        request.post({ url: '/OilWaste/UpdateErrorCode', data, params }),
    // 批量编辑报销状态
    batchUpdateBaoXiaoCode: (data, params) =>
        request.post({ url: '/OilWaste/BatchUpdateBaoXiaoCode', data, params }),
    // 编辑报销状态
    updateBaoXiaoCode: (data, params) =>
        request.post({ url: '/OilWaste/UpdateBaoXiaoCode', data, params }),
    // 获取油耗分页列表
    getList: (data, params) =>
        request.post({ url: '/OilWaste/GetPageList', data, params }),
    // 获取油耗日志
    getLogPageList: (data, params) =>
        request.post({ url: '/OilWaste/GetLogPageList', data, params }),
    // 获取油耗状态页面配置
    getPerKMConfig: (data, params) =>
        request.post({ url: '/OilWaste/GetPerKMConfig', data, params }),
    // 修改油耗状态页面配置
    updatePerKMConfig: (data, params) =>
        request.post({ url: '/OilWaste/UpdatePerKMConfig', data, params }),
    exportPageListUrl: `${baseURL}/OilWaste/ExportPageList`
}

// 保险设置
const ExamInsure = {
    // 新增保险设置
    add: (data, params) =>
        request.post({ url: '/ExamInsure/Add', data, params }),
    // 编辑保险设置
    update: (data, params) =>
        request.post({ url: '/ExamInsure/Update', data, params }),
    // 删除保险设置
    delete: (data, params) =>
        request.post({ url: '/ExamInsure/Delete', data, params }),
    // 获取保险设置分页列表
    getList: (data, params) =>
        request.post({ url: '/ExamInsure/GetPageList', data, params }),
    // 获取保险设置树形
    getTreeList: (data, params) =>
        request.post({ url: '/ExamInsure/GetTreeList', data, params }),
    // 是否公开
    updateIsPublic: (data, params) =>
        request.post({ url: '/ExamInsure/UpdateIsPublic', data, params }),
    // 查找单挑记录
    fineOne: (data, params) =>
        request.post({ url: '/ExamInsure/Find', data, params })
}

// 驾校管理-油卡管理-油卡充值
const ReCharge = {
    // 新增油卡充值
    add: (data, params) => request.post({ url: '/ReCharge/Add', data, params }),
    // 删除油卡充值记录
    delete: (data, params) =>
        request.post({ url: '/ReCharge/Delete', data, params }),
    // 获取油卡充值记录分页列表
    getList: (data, params) =>
        request.post({ url: '/ReCharge/GetPageList', data, params })
}
// 损耗管理-车损管理
const RepairWaste = {
    //删除
    delete: (data, params) =>
        request.post({ url: '/RepairWaste/Delete', data, params }),
    // 新增车损
    add: (data, params) =>
        request.post({ url: '/RepairWaste/Add', data, params }),
    // 修改车损
    update: (data, params) =>
        request.post({ url: '/RepairWaste/Update', data, params }),
    //获取车损分页
    getList: (data, params) =>
        request.post({ url: '/RepairWaste/GetPageList', data, params }),
    //导出车损
    exportPageListUrl: `${baseURL}/RepairWaste/ExportPageList`
    // exportPageList: (data, params) => request.get({ url: '/RepairWaste/ExportPageList', data, params}),
}

// 招生管理-销售管理-提成设置
const Commission = {
    // 获取提成设置分页列表
    getList: (data, params) =>
        request.post({ url: '/Commission/GetPageList', data, params }),
    // 新增提成设置
    add: (data, params) =>
        request.post({ url: '/Commission/Add', data, params }),
    // 修改提成设置
    update: (data, params) =>
        request.post({ url: '/Commission/Update', data, params }),
    // 修改提成内容
    updateCommissionContent: (data, params) =>
        request.post({
            url: '/Commission/UpdateCommissionContent',
            data,
            params
        }),
    // 修改其他内容
    updateOtherContent: (data, params) =>
        request.post({ url: '/Commission/UpdateOtherContent', data, params }),
    // 修改提成设置
    updateBonusContent: (data, params) =>
        request.post({ url: '/Commission/UpdateBonusContent', data, params }),
    // 删除提成设置
    delete: (data, params) =>
        request.post({ url: '/Commission/Delete', data, params }),
    //导出车损
    exportPageListUrl: `${baseURL}/Commission/ExportPageList`
}
//教练组
const CoachGroup = {
    // 获取教练组列表
    getList: (data, params) =>
        request.post({ url: '/CoachGroup/GetPageList', data, params }),
    // 新增
    add: (data, params) =>
        request.post({ url: '/CoachGroup/Add', data, params }),
    // 修改
    update: (data, params) =>
        request.post({ url: '/CoachGroup/Update', data, params }),
    // 删除
    delete: (data, params) =>
        request.post({ url: '/CoachGroup/Delete', data, params }),
    // 获取日志
    getLogList: (data, params) =>
        request.post({ url: '/CoachGroup/GetLogPageList', data, params })
}
//科目薪资
const ThemeSalary = {
    // 获取科目薪资
    getList: (data, params) =>
        request.post({ url: '/ThemeSalary/GetList', data, params }),
    // 新增科目薪资
    add: (data, params) =>
        request.post({ url: '/ThemeSalary/Add', data, params }),
    // 修改科目薪资
    update: (data, params) =>
        request.post({ url: '/ThemeSalary/Update', data, params }),
    // 删除科目薪资
    delete: (data, params) =>
        request.post({ url: '/ThemeSalary/Delete', data, params })
}

// 参数设置-车辆损耗项目
const RepairCategory = {
    getTreeList: (data, params) =>
        request.post({ url: '/RepairCategory/GetTreeList', data, params }),
    // 新增
    add: (data, params) =>
        request.post({ url: '/RepairCategory/Add', data, params }),
    // 修改
    update: (data, params) =>
        request.post({ url: '/RepairCategory/Update', data, params })
}
// 售后管理-客诉跟踪
const FeedBack = {
    // 获取分页
    getPageList: (data, params) =>
        request.post({ url: '/FeedBack/GetPageList', data, params }),
    // 新增
    add: (data, params) => request.post({ url: '/FeedBack/Add', data, params }),
    // 修改
    update: (data, params) =>
        request.post({ url: '/FeedBack/Update', data, params }),
    // 编辑客服专员
    updateUser: (data, params) =>
        request.post({ url: '/FeedBack/UpdateUser', data, params }),
    // 编辑状态
    updateCode: (data, params) =>
        request.post({ url: '/FeedBack/UpdateCode', data, params }),
    // 删除
    delete: (data, params) =>
        request.post({ url: '/FeedBack/Delete', data, params })
}

// app版本控制
const VersionManage = {
    // 新增版本
    add: (data, params) =>
        request.post({ url: '/VersionManage/Add', data, params }),
    // 修改
    update: (data, params) =>
        request.post({ url: '/VersionManage/Update', data, params }),
    // 删除版本
    delete: (data, params) =>
        request.post({ url: '/VersionManage/Delete', data, params }),
    // 获取分页
    getPageList: (data, params) =>
        request.post({ url: '/versionManage/GetPageList', data, params })
}
//预录入
const PreEntered = {
    // 获取预录入分页
    getList: (data, params) =>
        request.post({ url: '/PreEntered/GetPageList', data, params })
}

// 章节
const Section = {
    add: (data, params) => request.post({ url: '/Section/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Section/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Section/Delete', data, params }),
    getPageList: (data, params) =>
        request.post({ url: '/Section/GetPageList', data, params }),
    GetSelectList: (data, params) =>
        request.post({ url: '/Section/GetSelectList', data, params })
}

// 图标分类
const IconCategory = {
    getTreeList: (data, params) =>
        request.post({ url: '/IconCategory/GetTreeList', data, params }),
    // 新增
    add: (data, params) =>
        request.post({ url: '/IconCategory/Add', data, params }),
    // 修改
    update: (data, params) =>
        request.post({ url: '/IconCategory/Update', data, params })
}

// 图标
const Icon = {
    add: (data, params) => request.post({ url: '/Icon/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Icon/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Icon/Delete', data, params }),
    getPageList: (data, params) =>
        request.post({ url: '/Icon/GetPageList', data, params }),
    GetSelectList: (data, params) =>
        request.post({ url: '/Icon/GetSelectList', data, params })
}

// 图标
const Carousel = {
    add: (data, params) => request.post({ url: '/Carousel/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Carousel/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Carousel/Delete', data, params }),
    getPageList: (data, params) =>
        request.post({ url: '/Carousel/GetPageList', data, params }),
    getList: (data, params) =>
        request.post({ url: '/Carousel/GetSelectList', data, params })
}
// 文章
const Article = {
    add: (data, params) => request.post({ url: '/Article/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Article/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Article/Delete', data, params }),
    getPageList: (data, params) =>
        request.post({ url: '/Article/GetPageList', data, params })
}

// 答题
const Subject = {
    add: (data, params) => request.post({ url: '/Subject/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Subject/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Subject/Delete', data, params }),
    updateIsVip: (data, params) =>
        request.post({ url: '/Subject/UpdateIsVip', data, params }),
    getPageList: (data, params) =>
        request.post({ url: '/Subject/GetPageList', data, params }),
    UpdateSpecialCode: (data, params) =>
        request.post({ url: '/Subject/UpdateSpecialCode', data, params }),
    UpdateSkillType: (data, params) =>
        request.post({ url: '/Subject/UpdateSkillType', data, params })
}
// 微信用户
const WechantUser = {
    getPageList: (data, params) =>
        request.post({ url: '/WechantUser/GetPageList', data, params })
}

// 实缴管理
const Collect = {
    add: (data, params) => request.post({ url: '/Collect/Add', data, params }),
    update: (data, params) =>
        request.post({ url: '/Collect/Update', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Collect/Delete', data, params }),
    getPageList: (data, params) =>
        request.post({ url: '/Collect/GetPageList', data, params }),
    confirm: (data, params) =>
        request.post({ url: '/Collect/Confirm', data, params }),
    confirmBatch: (data, params) =>
        request.post({ url: '/Collect/ConfirmBatch', data, params }),
    exportPageListUrl: `${baseURL}/Collect/ExportPageList`,
    UpdateRemark: (data, params) =>
        request.post({ url: '/Collect/UpdateRemark', data, params })
}

//支出管理
const Expend = {
    add: (data, params) => request.post({ url: '/Expend/Add', data, params }),
    delete: (data, params) =>
        request.post({ url: '/Expend/Delete', data, params }),
    getPageList: (data, params) =>
        request.post({ url: '/Expend/GetPageList', data, params }),
    confirm: (data, params) =>
        request.post({ url: '/Expend/Confirm', data, params }),
    confirmBatch: (data, params) =>
        request.post({ url: '/Expend/ConfirmBatch', data, params }),
    exportPageListUrl: `${baseURL}/Expend/ExportPageList`,
    UpdateRemark: (data, params) =>
        request.post({ url: '/Expend/UpdateRemark', data, params })
}
//收支管理
const RevenueExpend = {
    add: (data, params) =>
        request.post({ url: '/RevenueExpend/Add', data, params }),
    getPageList: (data, params) =>
        request.post({ url: '/RevenueExpend/GetPageList', data, params }),
    update: (data, params) =>
        request.post({ url: '/RevenueExpend/Update', data, params }),
    exportPageListUrl: `${baseURL}/RevenueExpend/ExportPageList`,
    delete: (data, params) =>
        request.post({ url: '/ExpendThingCategory/Delete', data, params })
}
//答题小程序相关
const ApiWeChantUser = {
    //获取用户列表
    GetUserList: (data, params) =>
        request.post({ url: '/ApiWeChantUser/GetUserList', data, params }),
    // 答题记录
    GetSubjectRecordList: (data, params) =>
        request.post({
            url: '/ApiWeChantUser/GetSubjectRecordList',
            data,
            params
        }),
    UpdateAboutUs: (data, params) =>
        request.post({ url: '/Config/UpdateAboutUs', data, params })
}
const Common = {
    GetAboutUs: (data, params) =>
        request.post({ url: '/Common/GetAboutUs', data, params })
}
export default {
    login,
    loginout,
    Menu,
    Section,
    IconCategory,
    Icon,
    Carousel,
    Article,
    Subject,
    WechantUser,
    DriverShop,
    PublicDataDictionary,
    CompanyDataDictionary,
    TrainArea,
    Department,
    User,
    wokerment,
    EnterClass,
    ExpendThingCategory,
    Coach,
    Car,
    DataPower,
    Company,
    Upload,
    PayState,
    Student,
    PayOrder,
    ExamPlace,
    ThemeExamPay,
    Exam,
    OilCard,
    OilWaste,
    ExamInsure,
    ReCharge,
    RepairWaste,
    Commission,
    CoachGroup,
    RepairCategory,
    ThemeSalary,
    FeedBack,
    PayAccount,
    VersionManage,
    PreEntered,
    Clue,
    Collect,
    Expend,
    ApiWeChantUser,
    RevenueExpend,
    SpecialType,
    SkillType,
    Common
}
